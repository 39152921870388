.container {
  display:flex;
  max-width:1400px;
  margin:auto;
  margin-top:100px;
}

.container .formContainer {
  display:flex;
  flex-direction: column;
  flex:3;
}

.container .formContainer h2 {
  font-size:40px;
}

.container .formContainer input {
  text-decoration: none;
  width:50%;
  padding:10px;
  margin-top:10px;
  font-size:20px;
  border-color: #f5eceb;
  border:solid 3px #f5eceb;
}

.container .formContainer textarea {
  font-size:20px;
  margin-top:10px;
  width:80%;
  border-color: #f5eceb;
  border:solid 3px #f5eceb;
  resize:none;
  height:150px;
  padding:10px;
}

.container .formContainer button {
  width:25%;
  margin-top:20px;
  font-size:20px;
  padding:10px;
  border-radius:20px;
  background-color:#fff;
  border-color: #f5eceb;
  border:solid 3px #f5eceb;
  font-weight:bold;
}

.container .formImageContainer {
  flex:2;
}
