.container {
  display:flex;
  max-width: 1200px;
  margin:50px auto 100px auto;
  font-family:'Lato';
}

.container .mobileImageContainer {
  display:none;
}

.container .imageContainer {
  flex:1.5;
}

.container .imageContainer img {
  width:100%;
}

.container .textContainer {
  flex:1;
  padding:10px 80px;
}

.container .textContainer h3 {
  font-size:40px;
}
.container .textContainer p {
  font-size:18px;
}

@media (max-width: 1200px) {
  .container .imageContainer img {
    width:90%;
    margin-left:5%;
  }

  .container .textContainer h3 {
    font-size:30px;
  }

  .container .textContainer p {
    font-size:15px;
  }
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
  }

  .container .imageContainer {
    display:none;
  }

  .container .mobileImageContainer {
    display:flex;
    flex:1.5;
  }

  .container .mobileImageContainer img {
    width:90%;
    margin-left:5%;
  }
}
