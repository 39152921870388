.container {
  display:flex;
  width:100%;
  height:80px;
  background-color: #3B3145;
  align-items:center;
  justify-content: center;
  margin-top:0;
}

.container h2 {
  color:#fff;
  margin:0px 10px;
  font-size:40px;
  font-weight:500;
}

.container span {
  color:#fff;
  margin:0px 10px;
  font-size:30px;
  font-weight:300;
}

@media (max-width:900px) {
  .container h2 {
    color:#fff;
    margin:0px 10px;
    font-size:30px;
    font-weight:500;
  }

  .container span {
    color:#fff;
    margin:0px 10px;
    font-size:20px;
    font-weight:300;
  }
}

@media (max-width:600px) {
  .container h2 {
    color:#fff;
    margin:0px 10px;
    font-size:25px;
    font-weight:500;
  }

  .container span {
    color:#fff;
    margin:0px 10px;
    font-size:18px;
    font-weight:300;
  }
}
