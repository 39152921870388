.container {
  display:flex;
  margin-top:150px;
}

.container .mobileImageContainer {
  display:none;
}

.container .textBox {
  display:flex;
  flex:1;
  flex-direction: column;
  margin-left:50px;
}

.container .textBox h3 {
  font-size:40px;
  line-height: 55px;
}

.container .textBox h4 {
  font-size:25px;
  font-weight:400;
  margin-top:0px;
}

.container .textBox .listElement {
  font-size:20px;
}

.container .textBox .experienceButton {
  width:80%;
  background-color: #fff;
  border-radius: 30px;
  border:solid 5px #d5bcb4;
  font-size:20px;
  font-weight:600;
  padding:10px 40px 10px 40px ;
}

.container .videoContainer {
  flex:3;
  height:60vh;
  background-color:blue;
  box-shadow: -10px 14px 0px #3B3145;
  border:solid 1px #fff;
}


@media (max-width: 1400px) {
  .container .videoContainer {
    flex:3;
    height:60vh;
    background-color:blue;
    box-shadow: -10px 14px 0px #3B3145;
    border:solid 1px #fff;
  }
}

@media (max-width:1200px) {
  .container .textBox h3 {
    font-size:30px;
    line-height:30px;
  }

  .container .textBox h4 {
    font-size:20px;
  }

  .container .textBox .listElement {
    font-size:18px;
  }
}

@media (max-width:1000px) {
  .container {
    flex-direction:column;
  }

  .container .imageContainer {
    display:none;
  }

  .container .videoContainer {
    height:300px;
  }
}
