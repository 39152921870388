.container .banner {
  height:100vh;
  display: flex;
}

.container .banner .bannerText {
  width:45%;
  padding-left: 150px;
  padding-right:150px;
  height:60vh;
  margin-top:15vh;
}

.container .banner .bannerText h1 {
  font-size:60px;
  line-height: 20px;
  color:#b98858;
}

.container .banner .bannerText h2 {
  font-size:40px;
  font-weight:400;
}

.container .highlightBackground {
  background-color:#f9f8fa;
}

.container .highlightBackground .contentContainer {
  max-width:1400px;
  margin: auto;
  padding-top:100px;
}

.container .newsTileBackground {
    background-color: #F9ECEB;
    padding:50px 0px 50px 0px;
    margin-top:0;
    margin-bottom:0;
}

.container .newsTileBackground .newsTileTitle {
  max-width: 1300px;
  margin:10px auto 50px auto;
}

.container .newsTileBackground .newsTileTitle h2 {
  font-size:40px;
}

.container .newsTileBackground .newsTileContainer {
  max-width: 1400px;
  margin:10px auto 10px auto;
  display:flex;
}
