.unselected {
  color:#000;
  text-decoration:none;
  font-size:20px;
  line-height:40px;
}

.unselected:hover {
  color:#000;
  text-decoration:none;
  font-size:20px;
  line-height: 40px;
  border-bottom:solid 3px #F9ECEB;
}

.selected {
  color:#000;
  text-decoration:none;
  font-size:20px;
  line-height: 40px;
  border-bottom:solid 3px #F9ECEB;
  transform:translateY(3px);
}

@media (max-width:1800px) {
  .unselected {
    font-size:18px;
  }

  .unselected:hover {
    font-size:18px;
  }

  .selected {
    font-size:18px;
  }
}

@media (max-width:1500px) {
  .unselected {
    font-size:16px;
  }

  .unselected:hover {
    font-size:16px;
  }

  .selected {
    font-size:16px;
  }
}

@media (max-width:900px) {
  .unselected {
    font-size:14px;
  }

  .unselected:hover {
    font-size:14px;
  }

  .selected {
    font-size:14px;
  }
}
