.burgerNav {
  display:flex;
  cursor:pointer;
  width:30px;
  height:50px;
}

.burgerNav .topBar {
  width:30px;
  height:3px;
  border-radius: 2px;
  background-color:#bc8250;
  position:absolute;
  transform:translateY(15px);
  animation:crossforward;
  animation-duration:0.2s;
  animation-iteration-count:1;
  animation-fill-mode:forward;
}

.burgerNav .middleBar {
  width:30px;
  height:3px;
  border-radius: 2px;
  background-color:#bc8250;
  position:absolute;
  transform:translateY(25px);
  animation:crossforward;
  animation-duration:0.2s;
  animation-iteration-count:1;
  animation-fill-mode:forward;
}

.burgerNav .bottomBar {
  width:30px;
  height:3px;
  border-radius: 2px;
  transform:translateY(35px);
  background-color:#bc8250;
  position:absolute;
  margin-right:0;
  animation:crossbackward;
  animation-duration:0.2s;
  animation-iteration-count: 1;
  animation-fill-mode:forward;
}

@keyframe crossforward {
  from {
    background-color:#000;
    transform:rotate(45deg);
  }
  to {
    background-color:grey;
    transform:rotate(0deg);
  }
}

@keyframe crossbackward {
  from {
    background-color:#000;
    transform:rotate(0deg);
  }
  to {
    background-color:grey;
    transform:rotate(45deg);
  }
}
