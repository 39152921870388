.newsTileBackground {
  padding:50px 0px 50px 0px;
  margin-top:0;
  margin-bottom:0;
}

.newsTileBackground .newsTileTitle {
  max-width: 1300px;
  margin:10px auto 50px auto;
  display:flex;
}

.newsTileBackground .newsTileTitle h3 {
  font-size:30px;
  font-weight:400;
}

.newsTileBackground .newsTileContainer {
  max-width: 1400px;
  margin:10px auto 10px auto;
  display:flex;
}
