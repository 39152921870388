.container {
  width:100%;
  border:solid 1px black;
  margin-top:10px;
  background-color: #24152e;
  padding:10px 40px;
}

.container h2 {
  font-size:30px;
  color:#fff;
}
