.container {
  max-width:1200px;
  margin:50px auto 50px auto;
  text-align: center;
  z-index: 1;
}

.container .tint {
  max-width:1200px;
  padding:100px 300px;
  opacity: 0.9;
}

.container .tint h2 {
  font-size:50px;
  z-index: 2;
}

.container .tint h3 {
  font-size:25px;
}

.container .tint img {
  width:200px;
}

@media (max-width: 1200px) {
  .container .tint {
    padding:100px 200px;
  }
}


@media (max-width: 900px) {
  .container .tint {
    padding:100px 100px;
  }

  .container .tint h2 {
    font-size:40px;
    z-index: 2;
  }

  .container .tint h3 {
    font-size:20px;
  }
}

@media (max-width: 700px) {
  .container .tint {
    padding:60px 60px;
  }
}

@media (max-width: 500px) {
  .container .tint {
    padding:40px 40px;
  }

  .container .tint h2 {
    font-size:30px;
    z-index: 2;
  }

  .container .tint h3 {
    font-size:18px;
  }
  .container .tint img {
    width:150px;
  }
}
