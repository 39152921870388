.contentTileContainer {
  background-color: #F9F8FA;
  padding:50px 0px 50px 0px;
}

.newsTileBackground {
  background-color: #F9ECEB;
  padding:50px 0px 50px 0px;
  margin-top:0;
  margin-bottom:0;
}

.newsTileBackground .newsTileTitle {
  max-width: 1300px;
  margin:10px auto 50px auto;
}

.newsTileBackground .newsTileTitle h2 {
  font-size:40px;
}

.newsTileBackground .newsTileContainer {
  max-width: 1400px;
  margin:10px auto 10px auto;
  display:flex;
}

@media (max-width: 800px) {
  .newsTileBackground .newsTileContainer {
    flex-direction:column;
  }

  .newsTileBackground .newsTileTitle h2 {
    margin-left:10px;
  }
}
