* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.main-container {
  width: 100%;
}

.inner-container {
  padding: 0px 60px;
  display: flex;
  position: relative;
  /* background-image: url(../images/components.jpg);
  z-index: 10; */
}

.section {
  width: 25%;
  height: calc(100vh - 60px);
  background: transparent;
  cursor: pointer;
  border-right: 1px solid hsla(0, 0%, 100%, 0.1);
  border-left: 1px solid hsla(0, 0%, 100%, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.bottom-section {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 100px;
  opacity: 0.4;
  transition: all 0.5s ease-in-out;
}

.bottom-section img {
  width: 36px;
  margin-bottom: 32px;
}

.bottom-section span {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 32px;
}

.bottom-section span:after {
  content: "";
  left: 0;
  height: 2px;
  width: 100%;
  background: #ffc815;
  transform-origin: 0 0;
  transform: scaleX(0) translateZ(0);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  display: table;
}

.image-container {
  background-image: none;
  background-size: cover;
  width: 100%;
  height: 0%;
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 1s ease-in-out;
}

.services.active h1,
.components.active h1,
.products.active h1,
.system.active h1{
  height: 100%;
  opacity: 1;
}

.components.active .bottom-section span:after {
  transform: scaleX(1) translateZ(0);
}

.products.active .bottom-section span:after {
  transform: scaleX(1) translateZ(0);
}

.system.active .bottom-section span:after {
  transform: scaleX(1) translateZ(0);
}

.services.active .bottom-section span:after {
  transform: scaleX(1) translateZ(0);
}

.section h1 {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  transition: all 1s ease-in-out;
  color: #ffc815;
  height: fit-content !important;
}

.bars-icon {
  display: none;
}

@media screen and (max-width: 992px) {
  .nav-links ul li a,
  .login-btn {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 768px) {
  body {
    background: none !important;
  }
  .nav-links {
    width: 111%;
    background: #0b0100;
    position: absolute;
    z-index: -1;
    top: -43vh;
    transition: all 0.5s;
  }
  .show {
    top: 0;
  }
  .nav-links ul {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 66px;
    padding-right: 43px;
  }
  .nav-links ul li {
    margin-right: unset !important;
  }
  .nav-links ul li a,
  .login-btn {
    display: block;
    font-size: 1rem !important;
    padding: 10px 0;
  }
  .bars-icon {
    width: 50px !important;
    height: 50px !important;
    display: block;
  }
  .header-container {
    flex-direction: column;
  }
  .logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    align-items: center;
  }
  .image-container {
    background-image: none;
    width: 100%;
    height: 0%;
    z-index: -2;
    position: unset !important;
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 1s ease-in-out;
  }
  .components h1,
  .components .image-container,
  .components .bottom-section,
  .products h1,
  .products .image-container,
  .products .bottom-section,
  .system h1,
  .system .image-container,
  .system .bottom-section,
  .services h1,
  .services .image-container,
  .services .bottom-section {
    height: 100%;
    opacity: 1;
  }
  .inner-container {
    flex-direction: column;
    padding: 0;
  }
  .section {
    width: 100%;
    height: 50vh;
  }
  .section h1 {
    display: none;
  }
  .bottom-section {
    position: absolute;
  }
  .main-container {
    margin-top: -64px;
  }
  .components .image-container {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
