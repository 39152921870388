.container {
  display:flex;
}

.container .logo {
  flex:1;
}

.container .logo a {
  text-decoration: none;
}

.container .logo h1 {
  margin-left: 100px;
  font-weight:300;
  font-size:50px;
  color:#000;
}

.container .navigation {
  flex:1;
  display:flex;
  justify-content: space-around;
  align-items: center;
}

.container .mobileNavigation {
  display:none;
}

@media (max-width:1600px) {
  .container .navigation {
    flex:1.2;
  }
}

@media (max-width:1200px) {
  .container .logo h1 {
    font-size:40px;
  }

  .container .navigation {
    flex:1.8;
  }
}

@media (max-width:1000px) {
  .container .logo h1 {
    font-size:30px;
    margin-left:20px;
  }

  .container .navigation {
    flex:4;
  }
}

@media (max-width:900px) {
  .container .logo h1 {
    font-size:25px;
    margin-left:20px;
  }

  .container .navigation {
    flex:4.5;
  }
}

@media (max-width:700px) {
  .container {
    background-color: #fff;
  }
  .container .navigation {
    display:none;
  }

  .container .mobileNavigation {
    flex:0.6;
    display:flex;
    justify-content: space-around;
    align-items: center;
  }

  .container .logo h1 {
    margin-left: 50px;
    font-weight:500;
    font-size:40px;
    color:#bc8250;
  }
}
