.navButton {
  color:#000;
  text-decoration:none;
  font-size:20px;
  background-color: #F9ECEB;
  padding:10px 40px;
}

@media (max-width:1800px) {
  .navButton {
    font-size:18px;
  }
}

@media (max-width:1500px) {
  .navButton {
    font-size:16px;
  }
}

@media (max-width:900px) {
  .navButton {
    font-size:14px;
  }
}
