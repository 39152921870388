

.container .banner {
  height:100vh;
  background-color: #d5bcb4;
}

.container .banner .bannerText {
  color:#fff;
  width:45%;
  padding-left: 150px;
  height:60vh;
  margin-top:25vh;
}

.container .banner .mobileBannerText {
  display:none;
}

.container .banner .bannerText h1 {
  font-size:150px;
  line-height: 50px;
}

.container .banner .bannerText h2 {
  font-size:40px;
  font-weight:400;
}

.container .banner .bannerText .roundedButton {
  border:solid 3px #fff;
  font-size:20px;
  color:#fff;
  background-color: #d5bcb4;
  padding:10px 40px;
  align-items:center;
  border-radius: 25px;
  margin-top:10vh;
}

.container {
 background-color:#f9f8fa;
}

.container .contentContainer {
  max-width:1400px;
  margin: auto;
  padding-top:100px;
}

.container .contentContainer .contentTitle {
  font-size:50px;
  color:#d5bcb4;
  margin-top:100px;
}

.container .contentContainer .chocbotOpeningParagraph {
  display:none;
}

.container .highlightBackground {
  background-color: #F9ECEB;
  margin-top:50px;
}

.container .highlightBackground .highlightBackgroundContent {
  max-width:1400px;
  margin: auto;
  padding-top:50px;
  padding-bottom:50px;
}


@media (max-width: 1750px) {
  .container .banner .bannerText h1 {
    font-size:120px;
    line-height: 40px;
  }

  .container .banner .bannerText h2 {
    font-size:35px;
  }
}

@media (max-width: 1200px) {
  .container .banner .bannerText h1 {
    font-size:80px;
  }

  .container .banner .bannerText h2 {
    font-size:30px;
  }
}

@media (max-width:1000px) {
  .container .banner .bannerText {
    display:none;
  }

  .container .banner .mobileBannerText {
    display:flex;
    color:#fff;
    height:60px;
    font-size:60px;
    margin-top:60vh;
    justify-content:center;
  }

  .container .banner .mobileBannerText .mobileBannerTitle {
    height:60px;
  }

  .container .contentContainer .contentTitle {
    display:none;
  }

  .container .contentContainer .chocbotOpeningParagraph {
    display:flex;
    width:90%;
    margin-left:5%;
    font-size:40px;
  }
}

@media (max-width:600px) {
  .container .banner .mobileBannerText {
    font-size:30px;
    margin-top:70vh;
  }

  .container .contentContainer .chocbotOpeningParagraph {
    font-size:35px;
  }
}
