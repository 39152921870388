@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
}

.header-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  height: 60px;
  align-items: center;
  position: relative;
  z-index: 12;
}

.header-container .logo img {
  width: 64px;
  height: 24px;
}

.nav-links ul {
  display: flex;
  padding-inline-start: 0;
}

.nav-links ul li {
  list-style: none;
  text-transform: uppercase;
  margin-right: 30px;
}

.nav-links ul li:last-child {
  margin-right: 0;
}
.nav-links ul li a{
 text-decoration: none;
 letter-spacing: .08em;
 font-size: 1rem;
 color: white;
}

.login-btn {
  background: none;
  border: none;
  text-transform: uppercase;
  font-size: 1rem;
  color: white;
  letter-spacing: .08em;
}
