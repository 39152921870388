.container {
  display:flex;
  flex-direction: column;
  flex:1;
  margin:0px 50px 0px 50px;
}

.container .textContainer {
  width:95%;
  margin:10px auto 0 auto;
}

.container .textContainer .titleText {
line-height: 20px;
}

.container .textContainer .titleText h3 {
  font-size:20px;
  line-height: 20px;
}

.container .textContainer span {
  font-size:40px;
}

.container img {
  width:100%;
  box-shadow: 10px 14px 0px #3B3145;
}

.container .textContainer h4 {
  font-weight:300;
  font-size:20px;
}

@media (max-width: 800px) {
  .container .textContainer .titleText {
    height:40px;
  }
}
